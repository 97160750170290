<template>
  <v-container class="nopadding">
    <v-toolbar fixed class="toolbar-noshadow toolbar-page-name">
      <v-toolbar-title><strong>Reports</strong></v-toolbar-title>
    </v-toolbar>
    <v-container class="page-container">
      <v-row>
        <v-col cols="12" sm="6" :class="{ 'pb-0 mt-0': $vuetify.breakpoint.xsOnly }">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="selectedDate"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
            hide-details="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateFormatted"
                label="Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                hide-details="auto"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="selectedDate"
              type="month"
              format="MMMM - YYYY"
              :max="getEndDate"
              no-title
              scrollable
              hide-details="auto"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false">
                Cancel
              </v-btn>
              <v-btn text color="primary" @click="selectDate(selectedDate)">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col
          v-if="staffitems && staffitems.length > 0"
          cols="12"
          sm="6"
          :class="{ 'pt-0': $vuetify.breakpoint.xsOnly }"
        >
          <v-select
            v-model="selectedStaff"
            :items="staffitems"
            item-value="id"
            :disabled="!this.currentUser.is_superuser"
            hide-details="auto"
          >
            <template v-slot:item="{ item }">
              {{ item.first_name }} {{ item.last_name }}
            </template>
            <template v-slot:selection="{ item }">
              {{ item.first_name }} {{ item.last_name }}
            </template>
          </v-select>
        </v-col>
      </v-row>
      <div v-if="reportsCount < 5" class="mt-8 d-flex justify-center">
        <v-progress-circular
          indeterminate
          color="primary"
          :size="100"
        ></v-progress-circular>
      </div>
      <div v-else>
        <v-row>
          <v-col cols="6" sm="6" md="6" lg="4" xl="3">
            <v-card class="pa-4">
              <h2 class="grey--text text--darken-1 subtitle-1 font-weight-bold">
                CONTACTS
              </h2>
              <v-card-text class="px-0 d-flex align-center">
                <span class="black--text text-h2 font-weight-bold" cols="6">
                  {{ contacts.total }}
                </span>
                <span class="text-h5 ml-4">
                  {{ contacts.diff }}
                  <v-icon v-if="contacts.trend == ''"></v-icon>
                  <v-icon v-else-if="contacts.trend == '+'" color="green"
                    >mdi-arrow-up</v-icon
                  >
                  <v-icon v-else color="error">mdi-arrow-down</v-icon>
                </span>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="6" sm="6" md="6" lg="4" xl="3">
            <v-card class="pa-4">
              <h2 class="grey--text text--darken-1 subtitle-1 font-weight-bold">
                NEW BUSINESSES
              </h2>
              <v-card-text class="px-0 d-flex align-center">
                <span class="black--text text-h2 font-weight-bold" cols="6">
                  {{ business.total }}
                </span>
                <span class="text-h5 ml-4">
                  {{ business.diff }}
                  <v-icon v-if="business.trend == ''"></v-icon>
                  <v-icon v-else-if="business.trend == '+'" color="green"
                    >mdi-arrow-up</v-icon
                  >
                  <v-icon v-else color="error">mdi-arrow-down</v-icon>
                </span>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6" sm="6" md="6" lg="4" xl="3">
            <v-card class="pa-4">
              <h2 class="grey--text text--darken-1 subtitle-1 font-weight-bold">
                BUSINESSES CONTACTED
              </h2>
              <v-card-text class="px-0 d-flex align-center">
                <span class="black--text text-h2 font-weight-bold" cols="6">
                  {{ businesscontacted.total }}
                </span>
                <span class="text-h5 ml-4">
                  {{ businesscontacted.diff }}
                  <v-icon v-if="businesscontacted.trend == ''"></v-icon>
                  <v-icon v-else-if="businesscontacted.trend == '+'" color="green"
                    >mdi-arrow-up</v-icon
                  >
                  <v-icon v-else color="error">mdi-arrow-down</v-icon>
                </span>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6" sm="6" md="6" lg="4" xl="3">
            <v-card class="pa-4">
              <h2 class="grey--text text--darken-1 subtitle-1 font-weight-bold">
                EXTERNAL REFERRALS
              </h2>
              <v-card-text class="px-0 d-flex align-center">
                <span class="black--text text-h2 font-weight-bold" cols="6">
                  {{ external.total }}
                </span>
                <span class="text-h5 ml-4">
                  {{ external.diff }}
                  <v-icon v-if="external.trend == ''"></v-icon>
                  <v-icon v-else-if="external.trend == '+'" color="green"
                    >mdi-arrow-up</v-icon
                  >
                  <v-icon v-else color="error">mdi-arrow-down</v-icon>
                </span>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6" sm="6" md="6" lg="4" xl="3">
            <v-card class="pa-4">
              <h2 class="grey--text text--darken-1 subtitle-1 font-weight-bold">
                INTERNAL REFERRALS
              </h2>
              <v-card-text class="px-0 d-flex align-center">
                <span class="black--text text-h2 font-weight-bold" cols="6">
                  {{ internal.total }}
                </span>
                <span class="text-h5 ml-4">
                  {{ internal.diff }}
                  <v-icon v-if="internal.trend == ''"></v-icon>
                  <v-icon v-else-if="internal.trend == '+'" color="green"
                    >mdi-arrow-up</v-icon
                  >
                  <v-icon v-else color="error">mdi-arrow-down</v-icon>
                </span>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <!-- Chart Metric Controls -->
          <v-col cols="12" sm="6" class="mb-n5">
            <v-menu
              ref="chartDatePickerMenu"
              v-model="chartDatePickerMenu"
              :close-on-content-click="false"
              :return-value.sync="date"
              transition="scale-transition"
              offset-y
              min-width="auto"
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="formattedDate"
                  label="Filter Charts"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                :allowed-dates="
                  (date) =>
                    date <=
                    new Date(
                      Date.now() - new Date().getTimezoneOffset() * 60000
                    ).toISOString()
                "
                range
                no-title
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="chartDatePickerMenu = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="
                    $refs.chartDatePickerMenu.save(date);
                    dateChanged();
                  "
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col
            v-if="staffitems && staffitems.length > 0"
            cols="12"
            sm="6"
            :class="{ 'pt-0': $vuetify.breakpoint.xsOnly }"
          >
            <v-select
              v-model="selectedStaff"
              :items="staffitems"
              item-value="id"
              :disabled="!this.currentUser.is_superuser"
              hide-details="auto"
            >
              <template v-slot:item="{ item }">
                {{ item.first_name }} {{ item.last_name }}
              </template>
              <template v-slot:selection="{ item }">
                {{ item.first_name }} {{ item.last_name }}
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" sm="6">
            <v-btn-toggle v-model="chartOptions" mandatory tile group>
              <v-btn
                :value="filterOptions[0]"
                :class="currentFilter === 'weekly' ? 'filter-btn-selected' : ''"
                :color="currentFilter === 'weekly' ? 'primary' : ''"
                :elevation="currentFilter === 'weekly' ? 0 : elevation"
                @click="filterWeekly()"
              >
                Weekly
              </v-btn>
              <v-btn
                :value="filterOptions[1]"
                :class="currentFilter === 'monthly' ? 'filter-btn-selected' : ''"
                :color="currentFilter === 'monthly' ? 'primary' : ''"
                :elevation="currentFilter === 'monthly' ? 0 : elevation"
                @click="filterMonthly()"
              >
                Monthly
              </v-btn>
              <v-btn
                :value="filterOptions[2]"
                :class="currentFilter === 'yearly' ? 'filter-btn-selected' : ''"
                :color="currentFilter === 'yearly' ? 'primary' : ''"
                :elevation="currentFilter === 'yearly' ? 0 : elevation"
                @click="filterYearly()"
              >
                Yearly
              </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>
        <v-row>
          <!-- Charts -->
          <v-col class="chart-container" cols="12" sm="6" md="6" lg="4" xl="3">
            <v-card class="pa-4">
              <Chart
                v-if="show"
                :selectedDate="date"
                metricType="contacts"
                chartTitle="Contacts"
                :outreach_specialist_id="selectedStaff"
                :created_by__id="selectedStaff"
              ></Chart>
            </v-card>
          </v-col>
          <v-col class="chart-container" cols="12" sm="6" md="6" lg="4" xl="3">
            <v-card class="pa-4">
              <Chart
                v-if="show"
                :selectedDate="date"
                metricType="business"
                chartTitle="New Businesses"
                :outreach_specialist_id="selectedStaff"
                :created_by__id="selectedStaff"
              ></Chart>
            </v-card>
          </v-col>
          <v-col class="chart-container" cols="12" sm="6" md="6" lg="4" xl="3">
            <v-card class="pa-4">
              <Chart
                v-if="show"
                :selectedDate="date"
                metricType="businessContacted"
                chartTitle="Business Contacted"
                :outreach_specialist_id="selectedStaff"
                :created_by__id="selectedStaff"
              ></Chart>
            </v-card>
          </v-col>
          <v-col class="chart-container" cols="12" sm="6" md="6" lg="4" xl="3">
            <v-card class="pa-4">
              <Chart
                v-if="show"
                :selectedDate="date"
                metricType="externalReferrals"
                chartTitle="External Referrals"
                :outreach_specialist_id="selectedStaff"
                :created_by__id="selectedStaff"
              ></Chart>
            </v-card>
          </v-col>
          <v-col class="chart-container" cols="12" sm="6" md="6" lg="4" xl="3">
            <v-card class="pa-4">
              <Chart
                v-if="show"
                :selectedDate="date"
                metricType="internalReferrals"
                chartTitle="Internal Referrals"
                :outreach_specialist_id="selectedStaff"
                :created_by__id="selectedStaff"
              ></Chart>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="chart-container" cols="12" sm="6" md="6" lg="4" xl="3">
            <v-card class="pa-4">
              <Chart
                v-if="show"
                :selectedDate="date"
                metricType="technicalAssistance"
                :isChartTechnicalAssistance="true"
                chartTitle="Technical Assistance"
              ></Chart>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';

export default {
  name: 'Reports',
  components: {
    Chart: () => import('@/components/Chart'),
  },
  data() {
    return {
      selectedDate: new Date().toISOString().substr(0, 7),
      menu: false,
      modal: false,
      selectedFilter: 'weekly',
      selectedStaff: -1,
      staffitems: [
        {
          id: -1,
          first_name: 'All Staff',
        },
      ],
      businesscontacted: {
        diff: 0,
        total: 0,
        trend: '',
      },
      contacts: {
        diff: 0,
        total: 0,
        trend: '',
      },
      business: {
        diff: 0,
        total: 0,
        trend: '',
      },
      external: {
        diff: 0,
        total: 0,
        trend: '',
      },
      internal: {
        diff: 0,
        total: 0,
        trend: '',
      },
      reportsCount: 0,

      // Charts data
      date: [
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      ],
      chartDatePickerMenu: false,
      show: true,
      elevation: 2,
      chartOptions: '',
      formattedDate: '',
      // End Charts data
      breakpoint: this.$vuetify.breakpoint,
    };
  },
  computed: {
    ...mapGetters(['currentFilter', 'filterOptions']),
    getEndDate() {
      let currentDate = new Date();
      let endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 10);
      return endDate.toISOString().substr(0, 7);
    },
    dateFormatted() {
      return moment(this.selectedDate).format('MMMM YYYY');
    },
    currentUser() {
      return this.$store.getters.user;
    },
    userSelected() {
      return this.staffitems.find((staff) => staff.id == this.selectedStaff);
      //return selectedStaff ? selectedStaff.is_superuser : false
    },
    formatFilterDates() {
      let date;
      if (this.date.length === 2) {
        let date1, date2;
        if (new Date(this.date[1]) > new Date(this.date[0])) {
          date1 = this.date[0].split('-');
          date2 = this.date[1].split('-');
        } else {
          date1 = this.date[1].split('-');
          date2 = this.date[0].split('-');
        }
        return `${date1[1]}-${date1[2]}-${date1[0]} to ${date2[1]}-${date2[2]}-${date2[0]}`;
      } else {
        if (this.date.length === 1) {
          date = this.date[0].split('-');
        } else {
          date = this.date.split('-');
        }
        return `${date[1]}-${date[2]}-${date[0]}`;
      }
    },
  },
  created() {
    if (this.currentUser.is_superuser) {
      this.getStaffList();
    } else {
      this.staffitems = [this.currentUser];
      this.selectedStaff = this.currentUser.id;
    }
    this.getReports(this.selectedDate, this.selectedStaff);
    this.setCurrentFilter('weekly');
    this.chartOptions = this.currentFilter;
    this.formattedDate = this.formatFilterDates;
  },
  methods: {
    ...mapActions([
      'getAllStaff',
      'getStaff',
      'getBCReports',
      'getBReports',
      'getCReports',
      'getReferralReports',
      'setCurrentFilter',
    ]),
    getStaffList() {
      this.getAllStaff({ limit: 0, offset: 0 }).then(
        (response) => {
          console.log('response', response);
          const { results } = response.data;
          this.staffitems = [...this.staffitems, ...results];
        },
        (error) => {
          console.log('error', error);
        }
      );
    },
    selectDate(date) {
      this.reportsCount = 0;
      this.$refs.menu.save(date);

      const isAdmin = this.userSelected ? this.userSelected.is_superuser : false;

      this.getReports(date, this.selectedStaff, isAdmin);

      console.log('changed date', date);
      console.log('changed date', this.selectedStaff);
    },
    getReports(selectedDate, selectedStaff) {
      /* CONTACTS */
      const cReportsParams =
        selectedStaff >= 0
          ? { date_created: selectedDate, outreach_specialist__id: selectedStaff }
          : { date_created: selectedDate };
      this.getCReports(cReportsParams).then((response) => {
        this.contacts = Object.assign({}, this.contacts, response.data);
        this.reportsCount++;
      });

      /* NEW BUSINESSES */
      const bReportsParams =
        selectedStaff >= 0
          ? { date_created: selectedDate, created_by__id: selectedStaff }
          : { date_created: selectedDate };
      this.getBReports(bReportsParams).then((response) => {
        this.business = Object.assign({}, this.business, response.data);
        this.reportsCount++;
      });

      /* BUSINESSES CONTACTED */
      const bcReportsParams =
        selectedStaff >= 0
          ? { date_created: selectedDate, outreach_specialist__id: selectedStaff }
          : { date_created: selectedDate };
      this.getBCReports(bcReportsParams).then((response) => {
        this.businesscontacted = Object.assign({}, this.businesscontacted, response.data);
        this.reportsCount++;
      });

      /* INTERNAL REFERRALS */
      const rInternalReportsParams =
        selectedStaff >= 0
          ? { key: 'internal', date_created: selectedDate, created_by__id: selectedStaff }
          : { date_created: selectedDate, key: 'internal' };
      this.getReferralReports(rInternalReportsParams).then((response) => {
        this.internal = Object.assign({}, this.internal, response.data);
        this.reportsCount++;
      });

      /* EXTERNAL REFERRALS */
      const rExternalReportsParams =
        selectedStaff >= 0
          ? { key: 'external', date_created: selectedDate, created_by__id: selectedStaff }
          : { date_created: selectedDate, key: 'external' };
      this.getReferralReports(rExternalReportsParams).then((response) => {
        this.external = Object.assign({}, this.external, response.data);
        this.reportsCount++;
      });
    },
    parseDate(date) {
      return new Date(date).toISOString().substr(0, 7);
    },

    // Chart Methods
    dateChanged() {
      this.show = false;
      this.chartOptions = this.currentFilter;
      setTimeout(() => {
        this.show = true;
      }, 100);
      this.formattedDate = this.formatFilterDates;
    },
    // Button Filter Actions
    filterWeekly() {
      this.setCurrentFilter('weekly');
      this.dateChanged();
    },
    filterMonthly() {
      this.setCurrentFilter('monthly');
      this.dateChanged();
    },
    filterYearly() {
      this.setCurrentFilter('yearly');
      this.dateChanged();
    },
    // End Button Filter Actions
    // End Chart Methods
  },
  watch: {
    selectedStaff(val) {
      console.log('changed specialist', val);
      const isAdmin = this.userSelected ? this.userSelected.is_superuser : false;

      this.reportsCount = 0;
      this.getReports(this.selectedDate, val, isAdmin);
      this.dateChanged();
    },
  },
};
</script>
<style lang="scss" scoped>
.nopadding {
  padding: 0;
}
.page-container {
  padding-top: 16px;
  padding-bottom: 100px;
  @media screen and (max-width: 768px) {
    padding-bottom: 10px;
  }
}
.add-btn {
  bottom: 16px !important;
}
::v-deep {
  a {
    text-decoration: none;
  }
  @media screen and (max-width: 768px) {
    .toolbar-page-name {
      box-shadow: none !important;
      border-bottom: 1px solid lightgray;
    }
  }
}

.filter-btn-selected {
  color: white !important;
}
.chart-container {
  z-index: 0 !important;
  .v-card {
    min-height: 300px;
  }
}
</style>
